import * as React from 'react'

const writingList = [
  { title: 'Deep Learning Genre-based Music Recommendation Algorithm', description: 'Research paper presenting a comprehensive method to predict and recommend music using different details of the song. Specifically, proposes a deep neural network content-based fusion model to achieve this. Written for CS 4644: Deep Learning',link: 'https://drive.google.com/file/d/1fBBi1-3hl1cSHyRg6eVIiBZjZ3H75O74/view?usp=sharing'},
  { title: 'Ethical Analysis on Autonomous Vehicle Legislation', description: 'Academic paper for CS 3001: Computing & Society discussing the development of autonomous vehicles and surrounding legislations. An analysis of the different factors and technology to consider.', link: 'https://drive.google.com/file/d/1eStYsTUa30OA0GoSV1FKxy-WvwIwUMaE/view?usp=sharing'},
]

const researchList = [
    { title: 'Physically-based Neural Graphics', description: 'Developing novel data structures, PDE solvers, neural representations, and machine learning paradigms to significantly elevate the state of the art in physically-based animation.',link: 'https://www.vip.gatech.edu/teams/vye', imageUrl: './assets/vipLogo.jpeg', languages:'C + Python', frameworks:'NumPy + PyTorch'},
    { title: 'Crack Detection Algorithm Performance Evaluation System (CDA-PES)', description: 'Smart City Infrastructure lab project. Website built on flask that allows for quantititative measurement of crack detection algorithms.', link: 'https://www.vip.gatech.edu/teams/vvc', imageUrl: './assets/vipLogo.jpeg', languages:'Python + JavaScript', frameworks:'Flask + Angular', tools:'Google Cloud'},
    
    
];
// Your projects data
const projectList = [
  { title: 'Beetle App + Landing Page', description: 'Social event-planning mobile app that streamlines connecting friends across platforms. Test published on Apple App Store, along with over 300 beta users.', link: 'https://beetle-landing.vercel.app/', imageUrl: './assets/beetleLogo.png', languages:'JavaScript', frameworks:'React + Node.js + Express.js + React Native' },
  { title: '📈 Stock Prediction From Earnings Calls Protocol ', description: 'Using word embeddings from quarterly earnings calls to predict changes in S&P 500 stock market with regression.', link:'https://tuhm7.github.io/Stock-Prediction-ML/', languages:'Python', frameworks:'PyTorch + BERT + Longformer'},
  { title: 'signifeye-tool', description: 'Novel navigational tool for patients with visual disabilities, consisting of sensor-based eyewear with audio feedback and mobile application. Powered through computer vision and haptic feedback for both patient and caretaker.', link: 'https://devpost.com/software/signifeye?ref_content=my-projects-tab&ref_feature=my_projects', imageUrl:'./assets/treehacksLogo.jpeg', languages:'C## + JavaScript', frameworks:'Arduino + React Native' },
  { title: '🌲🏃 forest-run-game', description: 'fully interactive video game on gameboy advanced (GBA) emulator. 2-D visuals with character dodging obstacles, and splash screens for introduction. objective is to reach to given endpoint.', link: 'https://github.com/wonhochoi1/forest-run-game', languages:'C', frameworks:'mGBA' },
  { title: '2048-game', description: 'one player 2048 video game. fully interactive controls using WASD keys, and visuals include 4x4 grid for the game along with level indicator.', link: 'https://github.com/wonhochoi1/2048', imageUrl:'./assets/2048Logo.png', languages:'Java', frameworks:'Java Swing + JavaFX' },
  { title: 'my-personal-website', description: 'website using react.js + tailwind css. wanted to create a portfolio that reflected my character.', link: '', languages:'JavaScript + CSS', frameworks:'React + Tailwind + Three.js' }
];

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
);




const Projects = () => {
  return (
    
    <div className="bg-white p-4 lg:p-20">
      {/*Writings*/}
      <h2 className="text-2xl lg:text-3xl font-bold font-Mukta blue-gradient-text mb-6">Writing.</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-12">
        {writingList.map((project, index) => (
          <a key={index} href={project.link} className="aspect-ratio-16/9">
              <div className="relative bg-white h-auto min-h-[14rem] rounded-lg shadow-outline-glow hover:bg-gray-50 p-4 transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="flex mb-2"> {/* Ensure this div is set to flex to align items inline */}
                    {/* Adjust w-6 h-6 to change the size */}
                    <h3 className="font-bold text-xl">{project.title}</h3>
                </div>
                  <p className="text-gray-700">{project.description}</p>
                  {/* More project details */}
                  <div className="absolute bottom-0 right-0 mb-2 mr-2"> {/* Adjust margin for positioning */}
                    <Arrow />
                  </div>
              </div>
          </a>
        ))}
      </div>
      
      {/*Research*/}
      <h2 className="text-3xl font-bold font-Mukta blue-gradient-text mb-6">Research.</h2>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-12">
        {researchList.map((project, index) => (
          <a key={index} href={project.link} className="aspect-ratio-16/9">
              <div className="relative bg-white h-56 rounded-lg shadow-outline-glow hover:bg-gray-50 p-4 transition duration-300 ease-in-out hover:-translate-y-2 max-w-xs md:max-w-sm overflow-hidden">
                <div className="flex mb-2"> {/* Ensure this div is set to flex to align items inline */}
                    {project.imageUrl && <img src={project.imageUrl} alt="" className="w-6 h-6 mr-2" />} {/* Adjust w-6 h-6 to change the size */}
                    <h3 className="font-bold text-xl">{project.title}</h3>
                </div>
                
                  <p className="text-gray-700">{project.description}</p>
                  {/* More project details */}
                  <div className="flex flex-wrap">
                    <span class="mt-2 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{project.languages}</span>
                    <span class=" mt-2 bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">{project.frameworks}</span>
                  </div>
                  <div className="absolute bottom-0 right-0 mb-2 mr-2"> {/* Adjust margin for positioning */}
                    <Arrow />
                  </div>
              </div>
          </a>
        ))}
      </div>

      {/* Personal projects */}
      <h2 className="text-3xl font-bold font-Mukta blue-gradient-text text-sky-600 mb-6">Personal Projects.</h2>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {projectList.map((item, index) => (
          <a key={index} href={item.link} className="aspect-ratio-16/9 mb-8">
              <div className="relative bg-white h-56 rounded-lg shadow-outline-glow hover:bg-gray-50 p-4 transition duration-300 ease-in-out hover:-translate-y-2 max-w-xs md:max-w-sm overflow-hidden">
                <div className="flex mb-2"> {/* Ensure this div is set to flex to align items inline */}
                    {item.imageUrl && <img src={item.imageUrl} alt="" className="w-6 h-6 mr-2" />} {/* Adjust w-6 h-6 to change the size */}
                    <h3 className="font-bold text-xl">{item.title}</h3>
                </div>
                <p className="text-gray-700">{item.description}</p>
                  {/* More research details */}
                <div className="flex flex-wrap">
                  <span class="mt-2 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{item.languages}</span>
                  <span class=" mt-2 bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">{item.frameworks}</span>
                </div>
                <div className="absolute bottom-0 right-0 mb-2 mr-2"> {/* Adjust margin for positioning */}
                  <Arrow />
                </div>
              </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Projects;
