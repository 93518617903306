import * as React from 'react'
import { texts } from '../texts/homePageText';
import {texts2} from '../texts/secondPageTexts';
import Projects from './projects'
import Skills from './skills';
import EarthCanvas from '../canvas/Earth';
import About from './about';

function Home () {

    const [currentWord, setCurrentWord] = React.useState(0);
    const words = ["Developer", "Designer", "Creator", "Innovator"]; // Add more words as needed

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWord((currentWord) => (currentWord + 1) % words.length);
        }, 2000); // Change every 2000 milliseconds (2 seconds)
    
        return () => clearInterval(intervalId);
    }, [words.length]); // Include words.length in the dependency arr


    React.useEffect(() => {
        document.title = 'home';
    }, []);

    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const ListItem = ({ children }) => (
        <div style={{ display: 'flex', alignItems: 'start', marginLeft: '0.5em' }}>
          <GlobeSVG />
          <span style={{ marginLeft: '0.5em' }}>{children}</span>
        </div>
    );
      
    const GlobeSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
        </svg>
    );

    const secondExpTextJSX =(
        <p>
            <p>
                Building Maintenance Optimization Consultants, Inc. — <strong>Software Development Intern</strong>
            </p>
            <ListItem>
                Rebuilt core internal tool for building asset data collection, migrating tool's languages and platform for efficiency cost.
            </ListItem>
            
            <p>
                Georgia Tech Computer Graphics Lab — <strong>Machine Learning Researcher</strong>
            </p>
        </p>
    );
    
    const CurveSVG = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100" // Reduced height in the viewBox
            preserveAspectRatio="none"
            style={{ display: 'block', width: '100%', height: 'auto' }}
        >
            <path
                d="M0,150 L0,100 Q720,10 1440,10 L1440,150 Z"
                fill="#172554" // Replace with the desired color
            ></path>
        </svg>
    );

    const InvertedCurveSVG = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            preserveAspectRatio="none"
            style={{ display: 'block', width: '100%', height: 'auto' }}
        >
            <path
                d="M0,0 L0,10 Q720,90 1440,10 L1440,0 Z"
                fill="#1e3a8a" // Replace with the color of the bottom of the gradient or the next section
            ></path>
        </svg>
    );

    const SecondCurveSVG = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 100"
            preserveAspectRatio="none"
            style={{ display: 'block', width: '100%', height: 'auto' }}
        >
            <path
                d="M0,150 L0,90 Q720,10 1440,90 L1440,150 Z"
                fill="#1e3a8a" // Adjust the fill color as needed
            ></path>
        </svg>
    );







    return (
        <div className='home-page-container'>
            {/* 1. Home Page */}
            <div id='home' className="flex flex-col lg:flex-row items-center min-h-screen p-4 lg:p-8">
                {/* Left Side: Image */}
                <div className="w-full lg:w-auto lg:flex-1 flex flex-col justify-center lg:ml-20 transition-spacing max-w-md">
                    <img className='w-full max-w-sm mx-auto lg:w-auto object-contain rounded-tl-54 rounded-tr-54 transition-transform duration-300 ease-in-out hover:transform hover:-translate-y-8' src='./assets/professional.jpg' alt='Professional' />
                    <div className='pt-2 w-full max-w-sm mx-auto'>
                        <div className='flex items-center justify-start'>
                            <a href="mailto:wchoi323@gatech.edu" target="_blank" rel="noopener noreferrer">
                                <img src='./assets/mailLogo.png' alt='Email Logo' className='logo-size' />
                            </a>
                            <p className='text-sm font-Mukta ml-2'>email: wchoi323@gatech.edu</p>
                        </div>
                        <div className='flex items-center justify-start mt-2'>
                            <a href="https://www.linkedin.com/in/wonhochoi1" target="_blank" rel="noopener noreferrer">
                                <img src='./assets/linkedin.png' alt='LinkedIn Logo' className='logo-size' />
                            </a>
                            <p className='text-sm font-Mukta ml-2'>linkedin: linkedin.com/in/wonhochoi1</p>
                        </div>
                        <div className='flex items-center justify-start mt-2'>
                            <a href="https://github.com/wonhochoi1" target="_blank" rel="noopener noreferrer">
                                <img src='./assets/github.png' alt='GitHub Logo' className='logo-size' />
                            </a>
                            <p className='text-sm font-Mukta ml-2'>github: github.com/wonhochoi1</p>
                        </div>
                    </div>
                </div>

                {/* Right Side: Texts */}
                <div className="w-full lg:w-auto lg:flex-1 flex flex-col justify-center p-4 lg:pl-12 lg:pr-6 max-w-4xl mt-8 lg:mt-0">
                    {/* Big Texts */}
                    <div className='flex flex-row items-center gap-x-4 lg:gap-x-11'> 
                        <h2 className='font-inter font-medium text-4xl lg:text-7xl text-slate-700'>Hey, I'm Wonho 👋</h2>
                    </div>
                    
                    <div className="font-inter font-medium text-4xl lg:text-7xl [text-wrap:balance] text-slate-700">
                        <span>I'm a </span>
                        <span className="text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.7xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.7xl)*theme(lineHeight.tight))] overflow-hidden">
                            <ul className="block animate-text-slide-3 text-left leading-tight [&_li]:block">
                                <li>Developer</li>
                                <li>Researcher</li>
                                <li>Designer</li>
                                <li aria-hidden="true">Developer</li>
                            </ul>
                        </span>
                    </div>

                    <div className='flex flex-col py-3 mb-8'>
                        <div className='flex flex-col mb-8'>
                            <p className='font-inter text-2xl lg:text-4xl text-slate-700'>
                                <span>B.S. in Computer Science @ </span>
                                <span className='font-inter bg-gradient-to-r from-yellow-300 to-blue-600 bg-[length:100%_4px] bg-no-repeat bg-bottom'> Georgia Tech</span>
                            </p>
                        </div>

                        <div className='w-[75%] mt-6'>
                            <div className='flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-10 justify-center'>
                                <button onClick={() => handleScrollToSection('about')}
                                    className="font-Mukta text-lg py-2 px-4 text-white bg-gradient-to-r from-blue-400 to-violet-700 rounded-full ring-4 shadow-blue-500/50 shadow-lg hover:-translate-y-2 transition-transform duration-300 ease-in-out">
                                    About me!
                                </button>
                                <a href='https://drive.google.com/file/d/1QlrhT-aQR69BKzsNj4vW7HgiHPr6fvve/view?usp=sharing'
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="font-Mukta text-lg py-2 px-4 text-white bg-gradient-to-r from-blue-400 to-violet-700 rounded-full ring-4 shadow-blue-500/50 shadow-lg hover:-translate-y-2 transition-transform duration-300 ease-in-out">
                                    My Resume →
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="my-8 border-t border-gray-500 mx-auto w-3/4" />
            
            <div id='about' className='mb-16'>
                <About />
            </div>

            <CurveSVG />
            
            {/* 2. New Section for Education & Experience*/}
            <div id='experience' className="flex flex-col lg:flex-row items-center justify-center min-h-screen p-4 lg:p-8" style={{ background: 'linear-gradient(to bottom, #172554, #1e3a8a)' }}> {/* min-h-screen for 100vh */}
                
                {/* Column 1: Experience Details */}
                <div className="w-full lg:w-1/2 flex flex-col justify-center max-w-xl p-4 text-white order-2 lg:order-1">
                    <h2 className='font-Mukta text-lg'>
                        <p className='font-bold text-3xl gradient-text'>
                            Education
                        </p>
                        {texts2.first_text.map((point, index) => {
                            // Check if the line contains "Georgia Tech"
                            if (point.includes("Georgia Tech")) {
                                // Split the line into segments
                                const parts = point.split("Georgia Tech");
                                // Render the line with "Georgia Tech" in bold
                                return (
                                    <p key={index}>
                                        {parts[0]}
                                        <strong>Georgia Tech</strong>
                                        {parts[1]}
                                    </p>
                                );
                            } else if (point.includes("Threads:")) {
                                // Split the line into segments
                                const parts = point.split("Threads:");
                                // Render the line with "Georgia Tech" in bold
                                return (
                                    <p key={index}>
                                        {parts[0]}
                                        <strong>Threads:</strong>
                                        {parts[1]}
                                    </p>
                                );
                            } else {
                                // Render the line normally if it doesn't contain "Georgia Tech"
                                return <p key={index}>{point}</p>;
                            }
                        })}
                        <p className='mt-4 font-bold text-3xl gradient-text'>
                            2024
                        </p>
                        <p>{texts2.fifth_text} <strong>System Architecture Engineering Intern</strong> </p>
                        <p className='mt-4 font-bold text-3xl gradient-text'>
                            2023
                        </p>
                        {secondExpTextJSX}
                        <p className='mt-4 font-bold text-3xl gradient-text'>
                            2022
                        </p>
                        <p>{texts2.third_text} <strong>Undergradute Researcher</strong> </p>
                        <p className='mt-4 font-bold text-3xl gradient-text'>
                            2021
                        </p>
                        <p>{texts2.fourth_text}</p>
                    </h2>
                    {/* Add more paragraphs or elements as needed */}
                </div>
                {/* Column 2: Title */}
                <div className="w-full lg:w-1/2 flex flex-col justify-center max-w-md p-4 text-center lg:text-left order-1 lg:order-2 mb-8 lg:mb-0">
                    <h2 className='font-Roboto font-light text-5xl lg:text-8xl text-white whitespace-pre-line'>
                        {'Education\n&\nExperience'}
                    </h2>
                </div>
            </div>
            <InvertedCurveSVG />
            

            {/* 3. Project section */}
            <div id='projects'>
                <Projects></Projects>
            </div>
            
            <SecondCurveSVG />
            <div id='skills'>
                <Skills />
            </div>

        </div>
    )

}

export default Home