import * as React from 'react';
import {Link} from 'react-router-dom';
import EarthCanvas from '../canvas/Earth';

function Header () {
    const [expandHeader, setExpandHeader] = React.useState(true);
    const collapseTimeout = React.useRef(null);


    const toggleOption = () => {
        setExpandHeader(!expandHeader);
    };
    
    const handleMouseEnter = () => {
        if (collapseTimeout.current) {
            clearTimeout(collapseTimeout.current);
            collapseTimeout.current = null;
        }
        setExpandHeader(true);
    };

    const handleMouseLeave = () => {
        collapseTimeout.current = setTimeout(() => {
            setExpandHeader(false);
        }, 500); // Delay of 500 milliseconds
    };

    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const headerClasses = expandHeader ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full';
    const pulsateClass = !expandHeader ? 'animate-pulsate' : '';

    return (
        <div className='relative z-50'>
            <div className='flex flex-row px-4 lg:ml-10 mt-4 justify-between lg:justify-start items-center'>
                <div className='flex items-center cursor-pointer'>
                    <div className='h-10 w-10'>
                        <EarthCanvas />
                    </div>

                    <button 
                        onClick={toggleOption}
                        className="lg:hidden ml-4 p-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-16 6h16" />
                        </svg>
                    </button>

                    <div className={`absolute lg:relative top-full left-0 w-full lg:w-auto bg-white lg:bg-transparent
                        lg:flex items-center pl-6 gap-4 transition-all duration-300
                        ${expandHeader ? 'flex flex-col lg:flex-row' : 'hidden lg:flex'}
                        ${headerClasses}`}>
                        <button onClick={() => handleScrollToSection('home')} 
                            className="w-full lg:w-auto text-lg font-inter text-black p-2 lg:p-0 focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">
                            home
                        </button>
                        <button onClick={() => handleScrollToSection('about')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">about</button>
                        <button onClick={() => handleScrollToSection('experience')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">experience</button>
                        <button onClick={() => handleScrollToSection('projects')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">projects</button>    
                        <button onClick={() => handleScrollToSection('skills')} className="text-lg font-inter text-black focus:outline-none hover:-translate-y-1 transition-transform duration-300 ease-in-out">skills</button>    
                        <a href='https://drive.google.com/file/d/1QlrhT-aQR69BKzsNj4vW7HgiHPr6fvve/view?usp=sharing'
                            target="_blank" 
                            rel="noopener noreferrer"  className='text-lg font-inter text-black hover:-translate-y-1 transition-transform duration-300 ease-in-out'>resume!</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
